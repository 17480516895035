import React, { useState } from 'react';
import './PricingPage.css'; // Ensure this CSS file is created for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // FontAwesome React component
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import {  faGift ,faFire } from '@fortawesome/free-solid-svg-icons'; // Solid Icons
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons'; // Brand Icons

const PricingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
  const [selectedPlan, setSelectedPlan] = useState(''); // State to track which plan was selected
  const navigate = useNavigate(); // Initialize useNavigate

  const handleContactSalesClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  const handleOpenModal = (plan) => {
    setSelectedPlan(plan); // Set the selected plan
    setIsModalOpen(true);  // Show the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleOSClick = (os) => {
    console.log(`User selected ${os} for ${selectedPlan} plan`);
    setIsModalOpen(false); // Close the modal after OS selection
  };

  return (
    <div className="pricing-page">
      <header className="pricing-header">
        <h1>Whitematter Pricing Plans</h1>
        <p>Get started with WhiteMatter</p>
      </header>

      <div className="pricing-container">
        <div className="pricing-card trial">
          
          <h2>Free Trial</h2>
          <p className="price">$0<span>/month</span></p>
          <ul className="features">
            <li>First 2 months free</li>
            <li>$5.99 after trial expires</li>
            <li>Best for individual users</li>
            <li>Email support</li>
            <li>Limited AI insights</li>
          </ul>
          <button className="btn" onClick={() => handleOpenModal('Free Trial')}>Start Free Trial</button>
        </div>

        <div className="pricing-card basic">
  <h2>
    Standard 
  </h2>
  <div className="popular">
    <FontAwesomeIcon icon={faFire} className="fire-icon" /> Popular
  </div>
  <p className="price">$54<span>/year</span><br/> <span className='off' style={{color:'red'}} >25% off</span></p>
  <ul className="features">
    <li>First 3 months free</li>
    <li>Best for individual users</li>
    <li>Email support</li>
    <li>AI-driven neurofeedback</li>
  </ul>
  <button className="btn" onClick={() => handleOpenModal('Standard')}>Get Started</button>
</div>


        <div className="pricing-card essential">
          <h2>Enterprise</h2>
          <p className="price">Contact Us</p>
          <ul className="features">
            <li>Employer dashboards</li>
            <li>Best for organizations</li>
            <li>Priority support</li>
            <li>Advanced AI insights</li>
            <li>Manage team productivity and workload</li>
          </ul>
          <button className="btn" onClick={handleContactSalesClick}>Contact Sales</button>
        </div>
      </div>

      {/* Modal for selecting OS */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Choose your OS for the {selectedPlan} plan</h3>
            <div className="os-options-grid">
              <div className="os-option" onClick={() => handleOSClick('MacOS')}>
                <FontAwesomeIcon icon={faApple} className="os-icon" />
                <p>MacOS</p>
              </div>
              <div className="os-option" onClick={() => handleOSClick('Windows')}>
                <FontAwesomeIcon icon={faWindows} className="os-icon" />
                <p>Windows</p>
              </div>
            
            </div>
            <button className="btn close-btn" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingPage;
