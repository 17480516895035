import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/pricing">Pricing</a></li>
            <li><a href="/products">Products</a></li> {/* Updated link for Products */}
            <li><a href="/contact">Contact Us</a></li> {/* Updated link for Contact Us */}
          </ul>
        </div>
        <div className="footer-info">
          <h4>Contact Information</h4>
          <p>Email: <a href="mailto:info@erasys.co.uk">info@erasys.co.uk</a></p> {/* Added mailto link */}
        </div>
        
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Erasys LTD. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
