import React, { useState, useEffect, useRef } from 'react';
import './Products.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // FontAwesome React component
import { faTachometerAlt, faRobot, faWind, faChartLine, faKey } from '@fortawesome/free-solid-svg-icons'; // Solid Icons
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons'; // Brand Icons
import { useNavigate,useLocation } from 'react-router-dom'; // Import useNavigate for navigation
import Dashboard from './longevity1.png'; // Image for Dashboard
import AIImage from './ain.png'; // Image for AI
import BoxBreathingImage from './smaller_transformed_image_resized (1).png'; // Image for Box Breathing
import ProductivityImage from './apps_resized.png'; // Image for Productivity

const Products = () => {
  const [selectedApp, setSelectedApp] = useState('Dashboard');
  const [image, setImage] = useState(Dashboard);
  const [text, setText] = useState('Optimize your productivity with AI-powered apps integrated into Whitematter.');
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation();  // Get location to access the state

  // Ref for the "work-smarter-section"
  const workSmarterSectionRef = useRef(null);

  // Function to scroll to the work-smarter-section
  const scrollToSection = () => {
    workSmarterSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.state?.scrollToWorkSmarter) {
      workSmarterSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state]);

  // Function to update app state
  const handleAppClick = (app) => {
    setSelectedApp(app);
    switch (app) {
      case 'AI':
        setImage(AIImage);
        setText('AI is revolutionizing brain tracking with real-time analysis and actionable insights.');
        break;
      case 'Box Breathing':
        setImage(BoxBreathingImage);
        setText('Box Breathing helps you lower stress and manage cognitive fatigue throughout the day.');
        break;
      case 'Productivity':
        setImage(ProductivityImage);
        setText('Maximize your productivity by using focus sessions to prioritize tasks and receive feedback at the end of each session.');
        break;
      default:
        setImage(Dashboard);
        setText('Monitor health metrics in real-time.');
    }
  };

  // Automatically cycle through the apps
  useEffect(() => {
    const appList = ['Dashboard', 'AI', 'Box Breathing', 'Productivity'];
    let currentAppIndex = 0;

    const intervalId = setInterval(() => {
      const nextApp = appList[currentAppIndex];
      handleAppClick(nextApp);
      currentAppIndex = (currentAppIndex + 1) % appList.length; // Cycle back to the first app
    }, 3000); // Change apps every 3 seconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  // Function to handle navigation to the pricing page
  const goToPricing = () => {
    navigate('/pricing');
  };

  return (
    <div className="products-page">
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Whitematter</h1>
          <p className="hero-subtitle">
            <span className="gradient-text2">AI-powered</span> brain tracking for enhanced focus and productivity.
          </p>
          <div className="cta-buttons">
            <button className="cta-button learn-more" onClick={scrollToSection}>Learn More</button>
            <button className="cta-button buy-now" onClick={goToPricing}>Try For Free</button>
          </div>
        </div>
      </section>

      {/* Key Feature Section */}
      <section className="key-feature-section">
        <div className="key-feature-grid">
          <div className="key-feature-item">
            <h1>24/7</h1>
            <h3>Real-Time Brain Monitoring</h3>
            <p>Continuous tracking of cognitive performance to ensure optimal mental clarity throughout the day.</p>
          </div>
          <div className="key-feature-item">
            <h1>99%</h1>
            <h3>Precision in AI Insights</h3>
            <p>Accurate insights powered by AI, tailored to your unique brain patterns and daily activities.</p>
          </div>
          <div className="key-feature-item">
            <h1>Adaptive</h1>
            <h3>Personalized Cognitive Feedback</h3>
            <p>AI learns from your habits and provides personalized tips to improve focus, reduce stress, and boost productivity.</p>
          </div>
          <div className="key-feature-item">
            <h1>3000</h1>
            <h3>Data Points Processed</h3>
            <p>Leverage advanced AI processes over 3000 data points per hour, offering detailed reports on your mental well-being and productivity.</p>
          </div>
        </div>
      </section>

      {/* Work Smarter Section */}
      <section className="work-smarter-section" ref={workSmarterSectionRef}>
        <div className="work-smarter-content">
          <h1>Work Smarter</h1>
          <p>{text}</p>

          <div className="app-list-vertical">
            <div
              className={`app-item-vertical ${selectedApp === 'Dashboard' ? 'highlighted' : ''}`}
              onClick={() => handleAppClick('Dashboard')}
            >
              <FontAwesomeIcon icon={faTachometerAlt} />
              <span>Dashboard</span>
            </div>
            <div
              className={`app-item-vertical ${selectedApp === 'AI' ? 'highlighted' : ''}`}
              onClick={() => handleAppClick('AI')}
            >
              <FontAwesomeIcon icon={faRobot} />
              <span>AI</span>
            </div>
            <div
              className={`app-item-vertical ${selectedApp === 'Box Breathing' ? 'highlighted' : ''}`}
              onClick={() => handleAppClick('Box Breathing')}
            >
              <FontAwesomeIcon icon={faWind} />
              <span>Box Breathing</span>
            </div>
            <div
              className={`app-item-vertical ${selectedApp === 'Productivity' ? 'highlighted' : ''}`}
              onClick={() => handleAppClick('Productivity')}
            >
              <FontAwesomeIcon icon={faChartLine} />
              <span>Productivity</span>
            </div>
          </div>
        </div>

        <div className="work-smarter-image">
          <img src={image} alt="Work Smarter Preview" />
        </div>
      </section>

      {/* Parallax Section with Platform Buttons */}
      <section className="parallax-section">
        <div className="parallax-content">
          <h2>Enhance Your Performance</h2>
          <p>Discover how Whitematter can help you achieve new levels of productivity and mental well-being.</p>

          {/* Three Buttons for MacOS, Windows, and Trial with Text Below */}
          <div className="platform-buttons">
            <div className="platform-button-container">
              <button className="platform-button macos-button" onClick={goToPricing}>
                <FontAwesomeIcon icon={faApple} size="2x" /> {/* MacOS Icon */}
              </button>
              <p className="platform-text">MacOS</p>
            </div>
            <div className="platform-button-container">
              <button className="platform-button windows-button" onClick={goToPricing}>
                <FontAwesomeIcon icon={faWindows} size="2x" /> {/* Windows Icon */}
              </button>
              <p className="platform-text">Windows</p>
            </div>
            <div className="platform-button-container">
              <button className="platform-button trial-button" onClick={goToPricing}>
                <FontAwesomeIcon icon={faKey} size="2x" /> {/* Free Trial Icon */}
              </button>
              <p className="platform-text">Free Trial</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
