import React, { useState } from 'react';
import './Header.css';
import Logo from './Erasys-logo.png';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import FaBars and FaTimes
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the menu on hamburger click
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      {/* Hamburger Icon that changes to an X when menu is open */}
      <div className="hamburger-icon" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </div>

      {/* Logo */}
      <div className="logo1">
        <img src={Logo} alt="Erasys Logo" />
      </div>

      {/* Navigation Menu */}
      <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
        <ul>
          <li>
            <Link to="/" onClick={toggleMenu}>Home</Link>
          </li>
          <li>
            <Link to="/products" onClick={toggleMenu}>Products</Link>
          </li>
       
          <li>
            <Link to="/pricing" onClick={toggleMenu}>Pricing</Link>
          </li>
          <li>
            <Link to="/contact" onClick={toggleMenu}>Contact Us</Link>
          </li>
          
        </ul>
      </nav>

      {/* Try for Free button stays outside hamburger menu for both desktop and mobile */}
      <div className="actions">
        <Link to="/pricing" className="try-button">Try for free</Link>
      </div>
    </header>
  );
};

export default Header;
